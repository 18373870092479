import cache from "memory-cache";
import { BATCH_INGEST_TEMPLATE_FILEKIND, INSTRUCTIONS_MANUAL_FILEKIND, APPLICANT_MANUAL_FILEKIND } from '@/lib/config/constant';

const archiveFileKindMapCacheKey = "archiveFileKindMap";

export const putArchiveDocumentsFileKindMapInCache = (archiveDocumentsFileKindMap) => {
    cache.put(archiveFileKindMapCacheKey, archiveDocumentsFileKindMap, 24 * 1000 * 60 * 60);
}

export const downloadArchiveDocumentByFileKind = async (fileKind) => {
    const url = `/api/v1/rdg/admin/archive/document?fileKind=${fileKind}`;
    const fileBlob = await getCachedFileOrFromAPI(url);
    if(!fileBlob) {
        return false;
    }
    const archiveDocumentsFileKindMap = cache.get(archiveFileKindMapCacheKey);
    const fileName = archiveDocumentsFileKindMap && archiveDocumentsFileKindMap[fileKind] ? archiveDocumentsFileKindMap[fileKind] : fileKind;
    startDownload(fileBlob, fileName, false, true);
    return true;
}

export const downloadBatchIngestTemplate = async () => {
    const url = `/api/v1/rdg/admin/archive/document?fileKind=${BATCH_INGEST_TEMPLATE_FILEKIND}`;
    const fileBlob = await getCachedFileOrFromAPI(url);
    if(!fileBlob) {
        return false;
    }
    const archiveDocumentsFileKindMap = cache.get(archiveFileKindMapCacheKey);
    const fileName = archiveDocumentsFileKindMap && archiveDocumentsFileKindMap[BATCH_INGEST_TEMPLATE_FILEKIND] ? archiveDocumentsFileKindMap[BATCH_INGEST_TEMPLATE_FILEKIND] : "SAP_Metadata_worksheet_template.xlsx";
    startDownload(fileBlob, fileName, false, false);
    return true;
}

export const downloadInstructionsManualFile = async (isSameTab) => {
    const url = `/api/v1/rdg/admin/archive/document?fileKind=${INSTRUCTIONS_MANUAL_FILEKIND}`;
    const fileBlob = await getCachedFileOrFromAPI(url);
    if(!fileBlob) {
        return false;
    }
    const archiveDocumentsFileKindMap = cache.get(archiveFileKindMapCacheKey);
    const fileName = archiveDocumentsFileKindMap && archiveDocumentsFileKindMap[INSTRUCTIONS_MANUAL_FILEKIND] ? archiveDocumentsFileKindMap[INSTRUCTIONS_MANUAL_FILEKIND] : "Instructions_Manual.pdf";
    startDownload(fileBlob, fileName, true, isSameTab);
    return true;
}

export const downloadApplicantManualFile = async (isSameTab) => {
    const url = `/api/v1/rdg/admin/archive/document?fileKind=${APPLICANT_MANUAL_FILEKIND}`;
    const fileBlob = await getCachedFileOrFromAPI(url);
    if(!fileBlob) {
        return false;
    }
    const archiveDocumentsFileKindMap = cache.get(archiveFileKindMapCacheKey);
    const fileName = archiveDocumentsFileKindMap && archiveDocumentsFileKindMap[APPLICANT_MANUAL_FILEKIND] ? archiveDocumentsFileKindMap[APPLICANT_MANUAL_FILEKIND] : "Applicant_Manual.pdf";
    startDownload(fileBlob, fileName, true, isSameTab);
    return true;
}

const getCachedFileOrFromAPI = async (url) => {
    const cachedResponse = cache.get(url);
    let fileBlob = null;
    if (cachedResponse) {
        fileBlob = cachedResponse;
    } else {
        const hours = 24;
        const response = await fetch(url);
        if(response.ok) {
            fileBlob = await response.blob();
            cache.put(url, fileBlob, hours * 1000 * 60 * 60); 
        } else {
            fileBlob = null;
        }
        
    }
    return fileBlob;
}

export const startDownload = (blob, fileName, isPdf, isSameTab) => {
    let link = document.createElement('a');
    if (isPdf){
        blob = blob.slice(0, blob.size, "application/pdf");
        link.target = isSameTab ? "_self" :"_blank";
    } else {
        link.download = fileName;
    }
        
    const objUrl = window.URL.createObjectURL(blob);
    link.href = objUrl;
    link.click();
    //   For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
    }, 250);
}