export const BATCH_INGEST_TEMPLATE_FILEKIND = "batchIngestTemplate";
export const INSTRUCTIONS_MANUAL_FILEKIND = "instructionsManual";
export const APPLICANT_MANUAL_FILEKIND = "applicantManual";
export const fileKindInitialOptions = [{ label: "1 Batch Ingest Template", value: BATCH_INGEST_TEMPLATE_FILEKIND }, 
{ label: "2 Instructions Maunal", value: INSTRUCTIONS_MANUAL_FILEKIND }];

export const RDGProductTypes = {
    "rdg dataset": {
        value:"rdg dataset",
        displayName: "Dataset"
    },
    "rdg datafile":{
        value:"rdg datafile",
        displayName: "Data file"
    }
};

export const filesURL = {
    "Feedback":"https://docs.google.com/forms/d/e/1FAIpQLSdchdey9jOwdKNr90eFjQ5mUa_u9X2VpnlLklQx8t0PLjyKDQ/viewform",
    "Releasenotes": "https://docs.google.com/document/d/1LS1ZnLCiQC56wQEKHj9f1MH-XYWnQ9g-llW41JYuQ2c/"
};

export const FILE_UPLOAD_SECTION_LABEL = "Supporting documentation";
export const GENERAL_ERROR_SECTION_LABEL = "General Error";
export const AGENCY_ID_RDF_NAME = "imeta_agencyID";
export const AGENCY_DATASET_ID_RDF_NAME = "imeta_agencyDatasetID";
export const AGENCY_DATAFILE_ID_RDF_NAME ="imeta_agencyDatafileID";

export const RDG_HEADER_PORTAL_LABELS = {
    "icpsr-admin" : {
        role: "rdg-icpsr-admin",
        label: "ICPSR Administrator Portal"
    },
    "data-owner" : {
        role: "rdg-data-owner",
        label: "Data Owner Portal"
    },
    "metadata-team": {
        role: "rdg-metadata-team",
        label: "Metadata Input Team Portal"
    }
};
