import React from 'react';
import { useUserContext } from "../../lib/context/userContext";
import styles from '../../pages/error/error-styles.module.scss';
import RDGPageHeaderComponent from '@/components/header/RDGPageHeaderComponent';
import RDGPageFooterComponent from '@/components/footer/RDGPageFooterComponent';
import getConfig from 'next/config';
import { useSession } from "next-auth/react";

const { publicRuntimeConfig } = getConfig();

const RDGPageLayout = ({ children }) => {
  const envValue = (() => {
    const env = (publicRuntimeConfig.deploymentEnv || "").toUpperCase();
    return env != "PROD" ? env : undefined;
  })();
  const user = useUserContext();
  const { data: session } = useSession();
  return (
    <>
      {!session || !session.user || !user.roles|| user.roles.length <= 0 ?
        <p>...Loading</p>
        :
        <>
          <RDGPageHeaderComponent envValue={envValue} />
          {!user.isAllowed ?
            <><div className="container">
              <div className={styles["error-alert"]}>
                <div className={styles["error-alert-icon"]}><svg className={styles["icon-root"]} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg></div>
                <div className={styles["error-message"]}>
                  <h4 className={styles["text-message"]}>You do not have permissions to view this page</h4><br />
                  <h5>The requested page requires proper authorization for access. If you believe that you have received this message in error, please contact us via <a href="mailto:researchdatagov@umich.edu">researchdatagov@umich.edu</a> or submit a message to the feedback form to help resolve the issue.</h5>
                </div>
              </div>
            </div><br />
            </>
            :
            <div style={{ flex: '1' }}>
              {children}
            </div>
          }

          <RDGPageFooterComponent />
        </>


      }
    </>


  );
}

export default RDGPageLayout;
