import React from 'react';
import styles from './footer-styles.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import {filesURL} from "@/lib/config/constant";
import RDGImage from "@/components/common/image/RDGImage";


const RDGPageFooterComponent = () => {
    return  (  
    <footer className={styles.footer}>
        <Container className={styles.logos + " " + styles["footer-grid"]}>
            <Row>
                <Col md={6}>
                    <a href="#" className={styles["department-logo"]}><RDGImage src={`/images/rdg-logo.png`} alt="Research Data Gov" /></a>
                </Col>
                <Col md={6}>
                   <div className={styles.links}>
                     <div className="d-flex flex-row justify-content-end flex-wrap">
                        <div>
                            <a href={filesURL.Releasenotes} target={"_blank"} rel="noopener noreferrer">Release Notes</a>  &nbsp; &nbsp;
                        </div>
                        <div>
                            <a href={filesURL.Feedback} target={"_blank"} rel="noopener noreferrer">Feedback</a>
                        </div>
                      </div>
                    </div>
                 </Col>    
            </Row>
        </Container>
    </footer>
   );
}

export default RDGPageFooterComponent