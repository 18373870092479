import React from "react";
import Link from "next/link";
import styles from "./header-styles.module.scss";
import UserDetailsMenu from "../userDetail/UserDetailsMenu";
import { filesURL } from "@/lib/config/constant";
import { downloadInstructionsManualFile } from "@/lib/api/archiveFilesCachedFetch";
import RDGImage from "@/components/common/image/RDGImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserContext } from "@/lib/context/userContext";
import { RDG_HEADER_PORTAL_LABELS } from "../../lib/config/constant";
import { useEffect } from "react";
import { ID_TO_AGENCY } from "@/lib/api/server-side/radius-api/constants/groups";

const RDGPAgeHeaderComponent = ({ envValue }) => {
  useEffect(() => {
    if (typeof document !== undefined) {
      (async () => {
        await import("bootstrap/dist/js/bootstrap");
      })();
    }
  }, []);

  const user = useUserContext();

  let headerPortalLabel = "";
  let userRoles = new Set([]);
  let userAgencies = new Set([]);
  if (user && user?.rolesAndGroups) {
    Object.keys(user?.rolesAndGroups).map((roleAndAgency) => {
      if (/\d/.test(roleAndAgency)) {
        if (
          roleAndAgency?.includes(RDG_HEADER_PORTAL_LABELS["data-owner"].role)
        ) {
          userRoles.add(RDG_HEADER_PORTAL_LABELS["data-owner"].label);
        }
        if (
          roleAndAgency?.includes(
            RDG_HEADER_PORTAL_LABELS["metadata-team"].role
          )
        ) {
          userRoles.add(RDG_HEADER_PORTAL_LABELS["metadata-team"].label);
        }

        userAgencies.add(ID_TO_AGENCY[roleAndAgency.split("-").pop()]);
      } else {
        if (
          roleAndAgency.includes(RDG_HEADER_PORTAL_LABELS["icpsr-admin"].role)
        ) {
          userRoles.add(RDG_HEADER_PORTAL_LABELS["icpsr-admin"].label);
        }
      }
    });

    let isLabelSet = false;
    Object.keys(RDG_HEADER_PORTAL_LABELS).map((portalLabel) => {
      Object.keys(user?.rolesAndGroups).map((roleGroup) => {
        if (!isLabelSet) {
          if (roleGroup.includes(RDG_HEADER_PORTAL_LABELS[portalLabel].role)) {
            headerPortalLabel = RDG_HEADER_PORTAL_LABELS[portalLabel].label;
            isLabelSet = true; // setting it true, to take the role precedence into account once a higher role is set
          }
        }
      });
    });
  }

  return (
    <header className={styles["full-header"]}>
      <aside
        className={styles["site-banner"] + " " + styles["site-banner-info"]}
      >
        <div
          className={
            styles["px-3"] + " " + styles["stack"] + " " + styles["stack-2"]
          }
        >
          <header>ATTENTION</header>
          <div className={styles["site-banner-body"]}>
            <p>
              All ResearchDataGov accounts are being migrated to the new
              Researcher Passport.
            </p>
            <Link href="/migrationInstructions">More information</Link>
          </div>
        </div>
      </aside>
      {/* {envValue && (
        <div className="container-xxl">
          <div className="my-2 alert alert-primary d-flex" role="alert">
            {" "}
            <div className="align-self-center">
              {" "}
              <FontAwesomeIcon icon={["fas", "star"]} fixedWidth />
            </div>{" "}
            <div className="align-self-center">{envValue}</div>{" "}
          </div>
        </div>
      )} */}
      <section className="main-nav" id="sectionMainMenu">
        <div className={styles["show-for-sr"]}>Main Navigation</div>
        <div className={styles["main-menu-nav"]}>
          <section
            className={styles["global-nav"] + " " + styles["desktop-only"]}
          >
            <div className={styles["show-for-sr"]}>Static page navigation</div>
            <nav>
              <Link
                href={filesURL.Releasenotes}
                className={
                  styles["small-primary-button"] +
                  " " +
                  styles["button-style"] +
                  " " +
                  styles["button-size-small"]
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Release Notes
              </Link>
              <a
                className={
                  styles["small-primary-button"] +
                  " " +
                  styles["button-style"] +
                  " " +
                  styles["button-size-small"]
                }
                onClick={() => {
                  downloadInstructionsManualFile(false);
                }}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Ingest Guide
              </a>
              <Link
                href={filesURL.Feedback}
                className={
                  styles["small-primary-button"] +
                  " " +
                  styles["button-style"] +
                  " " +
                  styles["button-size-small"]
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Feedback
              </Link>
              <UserDetailsMenu />
            </nav>
          </section>
          <nav>
            <div className={styles["desktop-only"]}>
              <div className={styles["nav-title"]}>
                <Link href="/" className={styles.logo}>
                  <RDGImage
                    src={`/images/rdg-logo-color.png`}
                    alt="Research Data Gov"
                  />
                </Link>
              </div>
              <div>{headerPortalLabel}</div>
              <div className={styles["nav-title"]}>
                <Link href="/" className={styles["header-link"]}>
                  Ingest Home
                </Link>
                {user && (
                  <Link
                    href="#"
                    className={styles["header-link"]}
                    data-bs-toggle="modal"
                    data-bs-target="#accountModal"
                  >
                    My Role
                  </Link>
                )}
              </div>
            </div>
          </nav>
        </div>
      </section>
      {user && (
        <div
          className="modal"
          id="accountModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="accountModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title fs-5" id="accountModalLabel">
                  User Information
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body py-0">
                <table className="table border-0 table-light">
                  <tbody>
                    <tr>
                      <th className="border-0 bg-white" scope="row">
                        Name:
                      </th>
                      <td className="border-0 bg-white">{user.name}</td>
                    </tr>
                    <tr>
                      <th className="border-0 bg-white" scope="row">
                        Email:
                      </th>
                      <td className="border-0 bg-white">{user.email}</td>
                    </tr>
                    {userRoles?.size != 0 ? (
                      <tr>
                        <th className="border-0 bg-white" scope="row">
                          Role:
                        </th>
                        <td className="border-0 bg-white">
                          {Array.from(userRoles).join(", ")}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th className="border-0 bg-white" scope="row">
                          Role:
                        </th>
                        <td className="border-0 bg-white">Applicant</td>
                      </tr>
                    )}
                    {userAgencies?.size != 0 && (
                      <tr>
                        <th className="border-0 bg-white" scope="row">
                          Agency:
                        </th>
                        <td className="border-0 bg-white">
                          {Array.from(userAgencies).join(", ")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default RDGPAgeHeaderComponent;
