import React, { useEffect } from 'react'
import { IcpsrLoginDropdown } from "@icpsr/auth/lib/esm";
import getConfig from "next/config";
import { signIn, signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
const UserDetailsMenu = () => {

  const router = useRouter();
  const { data: session } = useSession();
  const nextConfig = getConfig();
  let loginReferrerPath = '/';

    useEffect(() => {
        if (router && router.asPath) {
          loginReferrerPath = router.asPath;
        }
      }, []);

  return(
    <IcpsrLoginDropdown
                      signIn={signIn}
                      signOut={() => {
                        signOut({
                          callbackUrl: window.location.origin + '/logout',
                        });
                      }
                      }
                      session={session}
                      providerId={
                        nextConfig.publicRuntimeConfig
                          .nextAuthKeycloakProviderId
                      }
                      currentPath={router.asPath}
                      authUiPath={nextConfig.publicRuntimeConfig.authUiPath}
                      myDashboardPath={nextConfig.publicRuntimeConfig.myDashboardPath}
                      referrer={
                        loginReferrerPath &&
                        loginReferrerPath.includes('/logout')
                        ? '/'
                        : loginReferrerPath
                    }

                    />
  )

}

export default UserDetailsMenu;